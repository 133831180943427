<template lang="pug">
om-modal(name="cancel-subscription-hu" @beforeOpen="beforeOpen")
  template(slot="modal-header")
    .w-100.text-center.text-uppercase.font-weight-bold.py-3 {{ $t('plan.cancel') }}
  template(slot="modal-body")
    loading-logo(v-if="loading")
    div(v-else)
      p {{ $t('plan.whyLeave') }}
      om-textarea#reason(v-model.trim="reason" ref="reason" rows="4")
  template(slot="modal-footer")
    .d-flex.justify-content-center
      om-button.mr-3.px-5(primary large @click="$modal.hide('cancel-subscription-hu')") {{ $t('stay') }}
      om-button.px-5(large secondary @click="cancel") {{ $t('planCancel') }}
</template>

<script>
  export default {
    props: {
      // eslint-disable-next-line
      PaymentAdapter: { type: Object },
    },
    data() {
      return {
        loading: false,
        reason: '',
      };
    },
    methods: {
      beforeOpen() {
        this.loading = false;
        this.reason = '';
      },

      async cancel() {
        this.$modal.hide('cancel-subscription-hu');
        this.$emit('successfulCancel', { cancel: true, reason: this.reason });
        this.loading = false;
      },
    },
  };
</script>
