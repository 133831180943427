<template lang="pug">
div
  om-button(
    primary
    block
    :class="{ 'mt-3': index !== 0 }"
    v-for="(reason, index) in Object.keys($t('cancelPlan.reasons'))"
    :key="reason.index"
    @click="$emit('selected', reason)"
  ) {{ $t(`cancelPlan.reasons.${reason}`) }}
</template>

<script>
  export default {};
</script>
