<template lang="pug">
.text-center
  .font-weight-bold {{ $t('cancelPlan.offer1') }}
  .font-weight-bold.font-size-1--5(v-html="$t('cancelPlan.offer2')")
  .font-weight-bold(v-html="$t('cancelPlan.offer3')")
</template>

<script>
  export default {};
</script>
