<template lang="pug">
om-modal(name="cancel-subscription-en" @beforeOpen="beforeOpen")
  template(slot="modal-header")
    .w-100.text-center.font-weight-bold.py-3(
      v-html="$t(`cancelPlan.pages.${currentPage}.title`, { firstName: getLoginFirstName })"
    )
    .text-center.font-size-1(v-html="$t(`cancelPlan.pages.${currentPage}.lead`)")
    .brand-modal-action-icon.cursor-pointer(@click="closeModal")
      close-icon(:width="12" :height="12" color="#AAB1C1")
  template(slot="modal-body")
    base-page(v-if="currentPage === 'base'" @selected="goTo($event)")
    offer-page(
      v-else-if="['stay', 'letUsAssistYou', 'beforeYouGo', 'anotherProvider2'].includes(currentPage)"
    )
    template(v-if="currentPage === 'anotherProvider'")
      om-textarea#detailedReason(
        v-model.trim="detailedReason"
        ref="detailedReason"
        rows="4"
        required
      )
        template(slot="error" v-if="$v.detailedReason.$error && !$v.detailedReason.required")
          span {{ $t('fieldRequired') }}
  template(slot="modal-footer")
    .d-flex.justify-content-center(v-if="currentPage !== 'base'")
      om-button.mr-3.px-5(
        primary
        large
        @click="acceptOffer"
        :class="{ loading: loading === 'staying' }"
        v-if="['letUsAssistYou', 'stay', 'beforeYouGo', 'anotherProvider2'].includes(currentPage)"
      ) {{ $t(`cancelPlan.buttons.acceptOffer`) }}
      om-button.mr-3.px-5(
        primary
        large
        v-if="currentPage === 'anotherProvider'"
        @click="closeModal"
        :class="{ loading: loading === 'staying' }"
      ) {{ $t(`cancelPlan.buttons.staying`) }}
      om-button.mr-3.px-5(
        primary
        large
        @click="goToSupport()"
        v-if="currentPage === 'letUsAssistYouTechnical'"
      ) {{ $t(`cancelPlan.buttons.contactSupport`) }}
      om-button.mr-3.px-5(
        primary
        large
        @click="goToCalendly()"
        v-if="currentPage === 'congratulate'"
      ) {{ $t(`cancelPlan.buttons.bookConsultation`) }}
      om-button.mr-3.px-5(
        primary
        large
        @click="closeModal"
        v-if="currentPage === 'cancellationConfirmed'"
      ) {{ $t('cancelPlan.buttons.close') }}
      om-button.px-5(
        secondary
        large
        :class="{ loading: loading === 'cancel' }"
        @click="cancel"
        v-if="!['cancellationConfirmed', 'congratulate'].includes(currentPage)"
      ) {{ $t('cancelPlan.buttons.cancel') }}
</template>

<script>
  import { mapGetters } from 'vuex';
  import { required } from 'vuelidate/lib/validators';
  import { stripTags } from '@/util';

  // eslint-disable-next-line
  import * as Pages from './Pages/index';

  export default {
    components: { ...Pages },
    props: {
      // eslint-disable-next-line
      PaymentAdapter: { type: Object },
    },
    data() {
      return {
        loading: false,
        reason: '',
        currentPage: 'base',
        detailedReason: null,
      };
    },
    computed: {
      ...mapGetters(['getLoginFirstName']),
    },
    validations: {
      detailedReason: {
        required,
      },
    },
    methods: {
      beforeOpen() {
        this.currentPage = 'base';
        this.reason = '';
        this.loading = false;
      },
      goToSupport() {
        this.closeModal();
        window.open('https://support.optimonk.com/hc/en-us/requests/new', '_blank');
      },
      goToCalendly() {
        this.closeModal();
        window.open('https://meetings.hubspot.com/optimonk-meeting/optimonk-demo', '_blank');
      },
      goTo(reason) {
        switch (reason) {
          case 'saveMoney':
            this.currentPage = 'stay';
            break;
          case 'notSatisfied':
            this.currentPage = 'letUsAssistYou';
            break;
          case 'anotherProvider':
            this.currentPage = 'anotherProvider';
            break;
          case 'technicalProblem':
            this.currentPage = 'letUsAssistYouTechnical';
            break;
          case 'other':
            this.currentPage = 'beforeYouGo';
            break;
        }

        this.reason = this.$t(`cancelPlan.reasons.${reason}`);
      },

      async acceptOffer() {
        this.loading = 'staying';
        const solution = stripTags(
          `${this.$t('cancelPlan.offer1')} ${this.$t('cancelPlan.offer2')} ${this.$t(
            'cancelPlan.offer3',
          )}`,
        );
        let reason = this.reason;
        if (this.detailedReason) {
          reason += `; Detailed reason: ${this.detailedReason}`;
        }
        try {
          await this.PaymentAdapter.keepSubscription(reason, solution);

          this.currentPage = 'congratulate';
        } catch (e) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.registerError'),
          });
        }

        this.loading = false;
      },

      closeModal() {
        this.$modal.hide('cancel-subscription-en');
      },

      async cancel() {
        this.loading = false;
        if (this.currentPage === 'anotherProvider') {
          this.$v.$touch();
          if (!this.$v.$invalid) {
            this.currentPage = 'anotherProvider2';
          }
          return;
        }

        this.currentPage = 'cancellationConfirmed';
        this.$emit('successfulCancel', { cancel: true, reason: this.reason });
      },
    },
  };
</script>
