<template lang="pug">
div
  template(v-if="type === 'activation'")
    .alert.alert-danger.text-center.mb-0 {{ $t('plan.notifications.overChargeUpgrade.message') }}
      .btn.btn-default.brand-btn-link.font-weight-bold.cursor-pointer(@click="$emit('action')") {{ $t('plan.notifications.overChargeUpgrade.button') }}
  template(v-else-if="type === 'maximum-total-update'")
    .alert.alert-danger.text-center.mb-0 {{ $t('plan.notifications.maximumTotalUpdate.message', { maximumTotal: formattedMaximumTotal }) }}
      .btn.btn-default.brand-btn-link.font-weight-bold.cursor-pointer(@click="$emit('action')") {{ $t('plan.notifications.maximumTotalUpdate.button') }}
</template>

<script>
  import { formatCurrencyByRegion } from '@/util';
  import { mapGetters, createNamespacedHelpers } from 'vuex';

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  export default {
    props: {
      type: { type: String, required: true },
    },
    computed: {
      ...mapGetters(['getRegion']),
      ...paymentGetters(['overrunMaximumTotal']),
      formattedMaximumTotal() {
        return formatCurrencyByRegion(this.getRegion, this.overrunMaximumTotal);
      },
    },
  };
</script>
