<template lang="pug">
om-modal(name="bank-transfer-change-plan" @beforeOpen="beforeOpen")
  template(slot="modal-body")
    template(v-if="currentPage === 'base'")
      .w-100.text-center.text-uppercase.font-weight-bold.py-3 {{ $t('payment.modal.changePlanTitle') }}
    template(v-else-if="currentPage !== 'base'")
      .text-center {{ $t('payment.modal.thankYou') }}
  template(slot="modal-footer")
    .d-flex.justify-content-center(v-if="currentPage === 'base'")
      template(v-if="isDowngradeCase")
        om-button.mr-3(
          primary
          large
          :to="{ name: 'payment_method', query: { redirectAfterAction: 'plan_settings' } }"
        ) {{ $t('payment.modal.changePaymentAndPlan') }}
      template(v-else)
        om-button.mr-3(primary large @click="continueToCheckout()") {{ $t('payment.modal.changePaymentAndPlan') }}
      om-button(secondary large @click="changePlan()") {{ $t('payment.modal.bankTransferChangePlan') }}
    .d-flex.justify-content-center(v-else)
      om-button.mr-3(primary large @click="$modal.hide('bank-transfer-change-plan')") {{ $t('close') }}
</template>

<script>
  import REQUEST_PLAN_CHANGE from '@/graphql/RequestPlanChange.gql';
  import { getAccountIdFromCookie } from '@/util';
  import { createNamespacedHelpers } from 'vuex';

  const { mapState, mapGetters } = createNamespacedHelpers('payment');

  export default {
    data() {
      return {
        currentPage: 'base',
      };
    },
    computed: {
      ...mapState(['selectedPlan', 'selectedPeriod']),
      ...mapGetters(['isDowngrade']),
      isDowngradeCase() {
        return this.isDowngrade(this.selectedPlan);
      },
    },
    methods: {
      beforeOpen() {
        this.currentPage = 'base';
      },

      async continueToCheckout() {
        this.$router.push({
          name: 'checkout',
          params: { step: 'payment', userId: getAccountIdFromCookie() },
          query: { package: `${this.selectedPlan.toUpperCase()}-${this.selectedPeriod}` },
        });
        this.$modal.hide('bank-transfer-change-plan');
      },

      async changePlan() {
        this.$apollo
          .mutate({
            mutation: REQUEST_PLAN_CHANGE,
            variables: {
              planName: this.selectedPlan,
              period: this.selectedPeriod,
            },
          })
          .then(() => {
            this.currentPage = 'thankYou';
          });
      },
    },
  };
</script>
